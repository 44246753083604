import React from "react";
import "./style.css";

const Footer = () => (<footer className={"footer"}>
    <div>
        <span>
            © Hej Software UG (haftungsbeschränkt)
        </span>

        <ul>
            <li>
                <a href="https://gethej.com/">Home</a>
            </li>

            <li>
                <a href="https://gethej.com/uber-uns/">Über uns</a>
            </li>

            <li>
                <a href="https://gethej.com/kontakt/">Kontakt</a>
            </li>

            <li>
                <a href="https://gethej.com/impressum/">Impressum</a>
            </li>

            <li>
                <a href="https://gethej.com/datenschutz/">Datenschutz</a>
            </li>
        </ul>
    </div>
</footer>);

export default Footer;
