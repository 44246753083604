import React from "react";
import { StatusContext } from "../../status-provider";
import StatusIcon from "../status-icon";
import { StatusItem } from "../status-item";
import "./style.css";

export default class StatusList extends React.PureComponent {
    public static contextType = StatusContext;

    public context!: React.ContextType<typeof StatusList.contextType>;

    public render() {
        if (!this.context) {
            return "Loading...";
        }

        const firstPartyOffline = this.context.findIndex(({ firstParty, down }) => firstParty && down) > -1;
        const thirdPartyOffline = this.context.findIndex(({ firstParty, down }) => !firstParty && down) > -1;

        console.log({
            firstPartyOffline,
            thirdPartyOffline,
        });

        let statusMsg = "All services online";
        let statusColor: "green" | "orange" | "red" = "green";
        if (firstPartyOffline) {
            statusMsg = "We seem to have some hiccups, we have been notified and are working on it!";
            statusColor = "red";
        } else if (thirdPartyOffline) {
            statusMsg = "A third party service seems to be having issues";
            statusColor = "orange";
        }

        const items = this.context.map((status) => (<StatusItem key={status.token} {...status} />));

        return (<div className={"statusList"}>
            <h1>Service status</h1>

            <div className={"globalStatusContainer"}>
                <StatusIcon big color={statusColor} />
                <h3>{statusMsg}</h3>
            </div>

            {items}
        </div>);
    }
}
