import React from "react";
import { Subject, timer } from "rxjs";
import { ajax } from "rxjs/ajax";
import { map, switchMap } from "rxjs/operators";
import Check from "../../common/models/check";
import { validateListObs } from "../../common/validation";

declare const API_URL: string;

export const StatusContext = React.createContext<Check[] | null>(null);

interface IStatusProviderState {
    readonly status: Check[] | null;
}

export class StatusProvider extends React.PureComponent<{}, IStatusProviderState> {
    public readonly unmount$ = new Subject<void>();

    public state: IStatusProviderState = {
        status: null,
    };

    public componentDidMount() {
        timer(0, 10000).pipe(
            switchMap(() => ajax.get(`${API_URL}/status`)),
            switchMap(({ response }) => validateListObs(Check, response)),
            map((status) => status.sort(({ firstParty }) => (firstParty) ? -1 : 1)),
        ).subscribe((status) => this.setState({ status }));
    }

    public componentWillUnmount() {
        this.unmount$.next();
    }

    public render() {
        const { status } = this.state;
        const { children } = this.props;

        return (
            <StatusContext.Provider value={status}>
                {children}
            </StatusContext.Provider>
        );
    }
}
