import React from "react";
import "./style.css";

interface IStatusIconProps {
    readonly color: "green" | "orange" | "red";
    readonly big?: boolean;
}

const StatusIcon = ({ color, big }: IStatusIconProps) => {
    const classes = [color, "statusIcon"];
    big && classes.unshift("big");

    return (<span className={classes.join(" ")} />);
};

export default StatusIcon;
