import React from "react";
import logo from "../../../../../res/logo.svg";
import "./style.css";

const Header = () => (<header className={"header"}>
    <div>
        <img src={logo} alt={"Hej"} />
    </div>
</header>);

export default Header;
