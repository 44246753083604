import React from 'react';
import ReactDOM from 'react-dom';
import Container from "./components/container";
import Footer from "./components/footer";
import Header from "./components/header";
import Legend from "./components/legend";
import StatusList from "./components/status-list";
import './index.css';
import * as serviceWorker from './serviceWorker';
import { StatusProvider } from "./status-provider";

ReactDOM.render(
    <React.StrictMode>
        <Header />

        <StatusProvider>
            <Container>
                <StatusList />

                <Legend />
            </Container>
        </StatusProvider>

        <Footer />
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
