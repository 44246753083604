import { plainToClass } from "class-transformer";
import { ClassType } from "class-transformer/ClassTransformer";
import { validate, ValidationError } from "class-validator";
import { EMPTY, from, Observable } from "rxjs";
import { concatMap, filter, map, toArray } from "rxjs/operators";

export class ValidationException extends Error {
    public constructor(
        public readonly errors: ValidationError[],
    ) {
        super(`Validation failed: ${JSON.stringify(errors, undefined, 2)}`);
    }

    public asReadableString() {
        return this.errors.map((error) => this.validationErrorAsString(error))
            .join("\n");
    }

    protected validationErrorAsString(error: ValidationError, parent?: string): string[] {
        const property = (parent) ? `${parent}.${error.property}` : error.property;

        if (error.children.length > 0) {
            return error.children.map((child) => this.validationErrorAsString(child, property))
                .reduce((errs, err) => {
                    errs.push(...err);
                    return errs;
                }, []);
        }

        const errors = [];
        for (const [key, value] of Object.entries(error.constraints || {})) {
            errors.push(`[${property}] {${key}} ${value}`);
        }

        return errors;
    }
}

/**
 * plainToClass on given object then validate with class-validator returning an observable
 */
export function validateObs<T>(
    cls: ClassType<T>,
    body: object,
    throwOnFailure = false,
    whitelist = false,
): Observable<T> {
    if (typeof body !== "object") {
        return EMPTY;
    }

    const parsed = plainToClass(cls, body || {});
    return from(validate(parsed, { whitelist })).pipe(
        filter((errors) => {
            if (errors.length > 0 && throwOnFailure) {
                throw new ValidationException(errors);
            }

            return errors.length === 0;
        }),
        map(() => parsed),
    );
}

/**
 * Validate a list of objects as given class
 */
export function validateListObs<T>(cls: ClassType<T>, bodies: object[], throwOnFailure = false): Observable<T[]> {
    return from(bodies).pipe(
        concatMap((body) => validateObs(cls, body, throwOnFailure)),
        toArray(),
    );
}
