import React from "react";
import StatusIcon from "../status-icon";
import "./style.css";

const Legend = () => (<div className={"legend"}>
    <span>
        <StatusIcon color={"green"} /> = Online
    </span>

    <span>
        <StatusIcon color={"orange"} /> = Third party service down
    </span>

    <span>
        <StatusIcon color={"red"} /> = First party service down
    </span>
</div>);

export default Legend;
