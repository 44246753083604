import { IsString, IsNotEmpty, IsBoolean, IsOptional, IsNumber, IsUrl } from "class-validator";

export default class Check {
    @IsString()
    @IsNotEmpty()
    readonly token!: string;

    @IsString()
    @IsNotEmpty()
    readonly alias!: string;

    @IsBoolean()
    readonly down!: boolean;

    @IsString()
    @IsOptional()
    readonly downSince!: string | null;

    @IsNumber()
    readonly uptime!: number;

    @IsUrl()
    readonly detailUrl!: string;

    @IsBoolean()
    readonly firstParty!: boolean;
}
