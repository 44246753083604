import React from "react";
import Check from "../../../../common/models/check";
import externalIcon from "../../../../../res/external-link.svg";
import "./style.css";
import StatusIcon from "../status-icon";

export const StatusItem = (props: Check) => {
    const { alias, detailUrl, down, firstParty } = props;

    let color: "green" | "orange" | "red" = "green";
    if (down) {
        color = (firstParty) ? "red" : "orange";
    }

    return (
        <div className={"statusListItem"}>
            <StatusIcon color={color} />

            <div>{alias}</div>

            <a href={detailUrl} target={"_blank"} rel={"noopener noreferrer"}>
                Details
                <img src={externalIcon} alt={" "} />
            </a>
        </div>
    );
};
